import * as Yup from 'yup';

export function initialValues() {
  return {
    expedient: {
      record_date: null,
      document_type: null,
      expedient_number: "",
      expedient_type: null,
      judgment: null,
      origin: null,
      external_expedient: "",
      external_dependence: "",
    },
    agreement: {
      agreement_date: null,
      promotion_date: null,
      clasification: null,
      category: null,
      extract: "",
      secretary_name: "",
      judge_name: "",
      publication_date: null,
      reserve_date: null,
    },
    sentence: {
      sentence_date: null,
      opinion_sentence_date: null,
      crime: null,
      gender_perspective_sentence: false,
      infantil_perspective_sentence: false,
      women_perspective_sentence: false,
      file: null,
      secretary_name: "",
      judge_name: "",
    }
  };
}

export function validateEditExpedientSchema() {
  return Yup.object({
    expedient: Yup.object().shape({
      record_date: Yup.date().required('Campo obligatorio'),
      expedient_number: Yup.string().required('Campo obligatorio'),
      expedient_type: Yup.object().required('Campo obligatorio'),
      judgment: Yup.object().required('Campo obligatorio'),
      origin: Yup.object().required('Campo obligatorio'),
      external_expedient: Yup.string().nullable(),
      external_dependence: Yup.string().nullable(),
    }),
  });
}

export function validateEditAgreementSchema() {
  return Yup.object({
    agreement: Yup.object().shape({
      agreement_date: Yup.date().required("Selecciona la fecha del acuerdo"),
      promotion_date: Yup.date().required("Selecciona la fecha de promoción"),
      clasification: Yup.object().required("Selecciona la clasificación del acuerdo"),
      category: Yup.array().required("Selecciona al menos uno de los rubros"),
      extract: Yup.string().required("Debes agregar el extracto al acuerdo"),
      secretary_name: Yup.string().required("Indica el nombre del Secretario de Acuerdos"),
      judge_name: Yup.string().required("Indica el nombre del Juez"),
      publication_date: Yup.date()
        .when('clasification', {
          is: (clasification) => ((clasification && clasification.value !== 4) || !clasification),
          then: () => Yup.date().required("Selecciona la fecha de publicación"),
          otherwise: () => Yup.date().nullable()
        }),
      reserve_date: Yup.date()
        .when('clasification', {
          is: (clasification) => clasification && clasification.value === 4,
          then: () => Yup.date().required("Selecciona la fecha de reserva"),
          otherwise: () => Yup.date().nullable()
        }),
    }),
  });
}

export function validateEditSentenceSchema() {
  return Yup.object({
    sentence: Yup.object().shape({
      sentence_date: Yup.date().required("Selecciona la fecha de carga de la sentencia"),
      opinion_sentence_date: Yup.date().required("Selecciona la fecha de dictamen de la sentencia"),
      crime: Yup.array().required("Debes seleccionar la acción o delito de la sentencia"),
      gender_perspective_sentence: Yup.boolean().nullable(),
      infantil_perspective_sentence: Yup.boolean().nullable(),
      women_perspective_sentence: Yup.boolean().nullable(),
      secretary_name: Yup.string().required("Indica el nombre del Secretario de Acuerdos"),
      judge_name: Yup.string().required("Indica el nombre del Juez"),
      // file: Yup.mixed().required('Es necesario adjuntar un archivo a la sentencia')
    }),
  });
}

export function validatePartsFormSchema() {
  return Yup.object().shape({
    partRegime: Yup.object().required('Campo requerido'),
    partBusinessName: Yup.string()
      .when('partRegime', {
        is: (partRegime) => partRegime && partRegime.value === 'moral',
        then: () => Yup.string().required("Campo requerido"),
        otherwise: () => Yup.string().nullable()
      }),
    partNames: Yup.string().required('Campo requerido')
      .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s.,]*$/, 'El nombre no es válido'),
    paternalSurname: Yup.string()
      .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s.,]*$/, 'El apellido no es válido')
      .required('Campo requerido'),
    maternalSurname: Yup.string()
      .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s.,]*$/, 'El apellido no es válido')
      .nullable(),
    partAlias: Yup.string().nullable(),
    partEmail: Yup.string().nullable().email('Correo no válido'),
    partPhone: Yup.string().nullable()
      .matches(/^[0-9]{10}$/, 'Teléfono no válido'),
    partAge: Yup.string().nullable()
      .matches(/^[0-9]*$/, 'Edad no válida'),
    partBirthday: Yup.date().nullable()
      .max(new Date(), 'La fecha de nacimiento no puede ser mayor a la fecha actual')
      .test('age-match', 'La edad no coincide con la fecha de nacimiento', function (value) {
        const age = this.parent.partAge;
        const birthDate = new Date(value);
        const today = new Date();
        const diff = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          diff--;
        }
        return diff === parseInt(age, 10);
      }), 
    partGender: Yup.object().nullable(),
    partSex: Yup.object().nullable(),
    partType: Yup.object().required('Campo requerido'),
    partClasification: Yup.object().required('Campo requerido')
  });
}

export function validationExpedientFormSchema(foundExpedient) {
  return Yup.object().shape({
    expedient: Yup.object().shape({
      ...(foundExpedient ? {
        record_date: Yup.date().nullable(),
        document_type: Yup.object().nullable(),
        expedient_number: Yup.string().nullable(),
        expedient_type: Yup.object().nullable(),
        judgment: Yup.object().nullable(),
        origin: Yup.object().nullable()
      } : {
        record_date: Yup.date().required("Selecciona una fecha"),
        document_type: Yup.object().required("Selecciona una opción"),
        expedient_number: Yup.string().required("Ingresa el numero de expediente"),
        expedient_type: Yup.object().required("Selecciona una opción"),
        judgment: Yup.object().required("Selecciona una opción"),
        origin: Yup.object().required("Selecciona una opción")
      }),
      external_expedient: Yup.string().nullable(),
      external_dependence: Yup.string().nullable(),
    }),

    agreement: Yup.object().when('expedient.document_type', {
      is: (document_type) => document_type.value === 1,
      then: () => Yup.object().shape({
        agreement_date: Yup.date().required("Selecciona la fecha del acuerdo"),
        promotion_date: Yup.date().required("Selecciona la fecha de promoción"),
        clasification: Yup.object().required("Selecciona la clasificación del acuerdo"),
        category: Yup.array().required("Selecciona al menos uno de los rubros"),
        extract: Yup.string().required("Debes agregar el extracto al acuerdo"),
        secretary_name: Yup.string().required("Indica el nombre del Secretario de Acuerdos"),
        judge_name: Yup.string().required("Indica el nombre del Juez"),
        publication_date: Yup.date()
          .when('clasification', {
            is: (clasification) => clasification && clasification.value !== 4,
            then: () => Yup.date().required("Selecciona la fecha de publicación"),
            otherwise: () => Yup.date().nullable()
          }),
        reserve_date: Yup.date()
          .when('clasification', {
            is: (clasification) => clasification && clasification.value === 4,
            then: () => Yup.date().required("Selecciona la fecha de reserva"),
            otherwise: () => Yup.date().nullable()
          }),
      }),
      otherwise: () => Yup.object().shape({
        agreement_date: Yup.date().nullable(),
        promotion_date: Yup.date().nullable(),
        clasification: Yup.object().nullable(),
        category: Yup.array().nullable(),
        extract: Yup.string().nullable(),
        secretary_name: Yup.string().nullable(),
        judge_name: Yup.string().nullable(),
        publication_date: Yup.date().nullable(),
        reserve_date: Yup.date().nullable(),
      })
    }),

    sentence: Yup.object().when('expedient.document_type', {
      is: (document_type) => document_type.value !== 1,
      then: () => Yup.object().shape({
        sentence_date: Yup.date().required("Selecciona la fecha de carga de la sentencia"),
        opinion_sentence_date: Yup.date().required("Selecciona la fecha de dictamen de la sentencia"),
        crime: Yup.array().required("Debes seleccionar la acción o delito de la sentencia"),
        file: Yup.mixed().required('Es necesario adjuntar un archivo a la sentencia'),
        secretary_name: Yup.string().required("Indica el nombre del Secretario de Acuerdos"),
        judge_name: Yup.string().required("Indica el nombre del Juez"),
      }),
      otherwise: () => Yup.object().shape({
        sentence_date: Yup.date().nullable(),
        opinion_sentence_date: Yup.date().nullable(),
        crime: Yup.object().nullable(),
        file: Yup.mixed().nullable(),
        secretary_name: Yup.string().nullable(),
        judge_name: Yup.string().nullable(),
      }),
      gender_perspective_sentence: Yup.boolean().nullable(),
      infantil_perspective_sentence: Yup.boolean().nullable(),
      women_perspective_sentence: Yup.boolean().nullable(),
    })
  });
}