import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import DateField from '../../../formikFields/DateField';
import SelectField from '../../../formikFields/SelectField';
import CheckField from '../../../formikFields/CheckField';
import DropzoneField from '../../../formikFields/DropzoneField';
import styles from '../ExpedientForm.module.css';
import { NotificationManager } from 'react-notifications';
import { CrimesMultiselect } from '../../../shared/crimes/CrimesMultiselect';
import { useFormikContext } from 'formik';
import TextField from '../../../formikFields/TextField';
import { getSentencesByExpedientId } from '../../api/sentences';
import useJuditialBulletin from '../../hooks/useJuditialBulletin';

export default function SentenceFormFields({expedientId}) {
  const [crimeOptions, setCrimeOptions] = useState([]);
  const [matterOptions, setMatterOptions] = useState([]);
  const [matter, setMatter] = useState(null);
  const { setFieldValue, values, errors } = useFormikContext();
  
  async function getCrimeOptions() {
    let authenticationToken = Cookies.get("authentication_token_03");
    try {
      const crimesResponse = await fetch(
        `${process.env.REACT_APP_URL_API_LARAVEL}/crimes/${matter}`, {
        method: 'GET',
        headers: {
          'Authorization': authenticationToken
        }
      });

      const crimesData = await crimesResponse.json();
      const crimeOptions = crimesData.data.crimes;
      if (!crimeOptions) {
        NotificationManager.error('Error al cargar los delitos', 'Error');
        return;
      }
      const options = crimeOptions.map((crime) => {
        return {
          value: crime.value,
          label: crime.label
        }
      });

      setCrimeOptions(options);
    } catch (error) {
      NotificationManager.error('Error al cargar los delitos', 'Error');
    }
  }

  async function getMatterOptions() {
    let authenticationToken = Cookies.get("authentication_token_03");
    try {
      const mattersResponse = await fetch(
        `${process.env.REACT_APP_URL_API_LARAVEL}/matters`, {
        method: 'GET',
        headers: {
          'Authorization': authenticationToken
        }
      });

      const mattersData = await mattersResponse.json();
      const matterOptions = mattersData.data;
      if (!matterOptions) {
        NotificationManager.error('Error al cargar las materias', 'Error');
        return;
      }
  
      if('message' in matterOptions) { 
        delete matterOptions.message;
      }
      
      const options = Object.values(matterOptions).map((matter) => {
        return {
          value: matter.id,
          label: matter.name
        }
      });

      setMatterOptions(options);
    }
    catch (error) {
      NotificationManager.error('Error al cargar las materias', 'Error');
    }
  }

  useEffect(() => {
    if (matter) {
      getCrimeOptions();
    }
  }, [matter]);

  useEffect(() => {
    const getSentences = async() => {
      const lastSentence = await getSentencesByExpedientId(expedientId);
      if(lastSentence) {
        const {attributes = {}} = lastSentence;
        setFieldValue('sentence.secretary_name', attributes.sentenceSecretaryName || '');
        setFieldValue('sentence.judge_name', attributes.sentenceJudgeName || '');
      }
    }

    if(expedientId) getSentences();
  }, [expedientId]);

  const handleChangeCrimes = (key, value) => {
    if(value == '') {
    } else {
      let idsCrimesSelected = `${value}`.split(',');
      idsCrimesSelected = idsCrimesSelected.map((item) => parseInt(item));
      const findedCrimes = crimeOptions.filter((crime) => idsCrimesSelected.includes(crime.value));
      setFieldValue('sentence.crime', findedCrimes);
    }
  }

  const getMatterLabel = (id) => {
    const matter = matterOptions.find((matter) => matter.value === id);
    return matter ? matter.label : '';
  }

  return (
    <>
      <div className={styles.containerRowFields}>
        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Fecha de carga de Sentencia
            </div>
            <div className={styles.calendarFieldApparience}>
              <div className={styles.calendarGroup}>
                <div className={styles.datePickerElement}>
                  <DateField
                    name="sentence.sentence_date"
                    id="sentence_date"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className={styles.inputDate}
                    placeholderText={"Clic para seleccionar fecha"}
                  />
                </div>
                <div className={styles.iconPicker}>
                  <FontAwesomeIcon icon={faCalendarDays} className={styles.calendarIcon} />
                </div>
              </div>
            </div>
            <small>Fecha en que se cargó la sentencia</small>
          </Form.Group>
        </div>

        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Fecha de dictamen de la Sentencia
            </div>
            <div className={styles.calendarFieldApparience}>
              <div className={styles.calendarGroup}>
                <div className={styles.datePickerElement}>
                  <DateField
                    name="sentence.opinion_sentence_date"
                    id="opinion_sentence_date"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className={styles.inputDate}
                    placeholderText={"Clic para seleccionar fecha"}
                  />
                </div>
                <div className={styles.iconPicker}>
                  <FontAwesomeIcon icon={faCalendarDays} className={styles.calendarIcon} />
                </div>
              </div>
            </div>
            <small>Fecha en que se dictaminó la sentencia</small>
          </Form.Group>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Nombre del juez
            </div>
            <TextField
              id="sentence.judge_name"
              name="sentence.judge_name"
              placeholder="Nombre del Juez"
              className={styles.input}
            />
            <small> Escribe aqui el nombre del Juez </small>
          </Form.Group>
        </div>

        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Nombre del Secretario de acuerdos
            </div>
            <TextField
              id="sentence.secretary_name"
              name="sentence.secretary_name"
              placeholder="Nombre del Juez"
              className={styles.input}
            />
            <small>Escribe aqui el nombre del Secretario de Acuerdos</small>
          </Form.Group>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Materia
            </div>
            <SelectField
              id="sentence.matter"
              name='sentence.matter'
              placeholder="Selecciona una opción"
              onFocus={() => getMatterOptions()}
              onChange={(e) => setMatter(e.value)}
              options={matterOptions}
            />
            <small>Selecciona la materia de la sentencia</small>
          </Form.Group>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Acción o delito
            </div>
            <CrimesMultiselect
              isDisabled={crimeOptions.length === 0}
              matter={getMatterLabel(matter)}
              changeMainForm={handleChangeCrimes}
              isError={errors && errors.sentence && errors.sentence.crime}
              isUpdate={true}
              handleChangeUpdate={handleChangeCrimes}
            />
            {errors && errors.sentence && errors.sentence.crime && 
              <div className={'ErrorMessagesShared_feedbackErrorMessages__3JzUK'} style={{color: 'red'}}>
                * {errors.sentence.crime}
              </div>
            }
            <small>Selecciona la acción o delito especificado en la sentencia</small>
            
          </Form.Group>
        </div>
      </div>

      <div className={styles.instructionsText}>
        <span>A continuación, marca las casillas correspondientes de acuerdo a la naturaleza de la sentencia</span>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize}>
          <div className={styles.checkGroupField}>
            <CheckField
              id="gender_perspective_sentence"
              name="sentence.gender_perspective_sentence"
              className={styles.checkAparience}
            />
            <div className={styles.fieldLabel}>
              <label htmlFor="gender_perspective_sentence" style={{ marginBottom: 0 }}>
                Sentencia dictada con perspectiva de género
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize}>
          <div className={styles.checkGroupField}>
            <CheckField
              id="infantil_perspective_sentence"
              name="sentence.infantil_perspective_sentence"
              className={styles.checkAparience}
            />
            <div className={styles.fieldLabel}>
              <label htmlFor="infantil_perspective_sentence" style={{ marginBottom: 0 }}>
                Sentencia dictada con perspectiva de infancia y adolescencia
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize}>
          <div className={styles.checkGroupField}>
            <CheckField
              id="women_perspective_sentence"
              name="sentence.women_perspective_sentence"
              className={styles.checkAparience}
            />
            <div className={styles.fieldLabel}>
              <label htmlFor="women_perspective_sentence" style={{ marginBottom: 0 }}>
                Sentencia dictada con perspectiva de violencia contra las mujeres
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize} style={{ marginTop: '2rem' }}>
          <div className={styles.fieldLabel}>
            * Adjunta el archivo de la sentencia
          </div>

          <DropzoneField
            name="sentence.file"
          />
        </div>
      </div>
    </>
  );
}
