import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Card, Container, Form } from "react-bootstrap";
import NavBar from "../../layout/shared/NavBar";
import Select from "react-select";
import {
  getOptionsKindJudgment,
  getOptionsLegalWays,
  selectStylesDefault,
  fieldsValidation,
  selectStylesError,
  renderErrorsByInputName
} from "../my_expedients/expedients_list_logic";
import { saveExpedientEdited, getDataExpedientToEdit } from "./modify_expedients_logic";
import LoaderContext from "../../layout/shared/loader_context";
import Loader from "../../layout/shared/Loader";
import useLegalRepresentativeText from "../my_expedients/hooks/useLegalRepresentativeText";
import { regimeSelectorOptions } from "../../../services/dataFormatting/selectInput";
import { getLabelFromDictionary } from "../../../config/dictionary";
import Cookies from "js-cookie";
import { getDecodedJwt } from "../../../services/dataFormatting/getDecodedJwt";
import { getCrimes } from "../my_expedients/expedients_list_logic";
import { PartiesModal } from "../details_expedient/modals/partiesModal/PartiesModal";
import { PartiesWithOutModal } from "../details_expedient/modals/partiesModal/PartiesWithOutModal";
import { CrimesServices } from "../../../services/crimes/crimesServices";
import { editExpedient } from "../../juditial_bulletin/api/expedients";
import { CrimesMultiselect } from "../../shared/crimes/CrimesMultiselect";
const customStyles = {
  ...selectStylesDefault,
  menu: (provided, state) => ({
    ...provided,
    position: 'relative',
    width: '99%',
    margin: '0 auto'
  })
};

const EditExpedient = (props) => {
  const initValues = {
    expedient_number: '',
    actor_regime: '',
    actor_business_name: '',
    actor_name: '',
    actor_firstname: '',
    actor_secondname: '',
    actor_email: '',
    lawyer_actor_name: '',
    lawyer_actor_firstname: '',
    lawyer_actor_secondname: '',
    lawyer_actor_email: '',
    defendant_regime: '',
    defendant_business_name: '',
    defendant_name: '',
    defendant_firstname: '',
    defendant_secondname: '',
    defendant_email: '',
    lawyer_defendant_name: '',
    lawyer_defendant_firstname: '',
    lawyer_defendant_secondname: '',
    lawyer_defendant_email: '',
    kind_judgment: null,
    via: null,
    justification: ''
  };
  const expedientId = props.match.params.expedientId;
  const [kindJudgmentOptions, setKindJudgmentOptions] = useState([]);
  const [legalWaysOptions, setLegalWaysOptions] = useState([]);
  const [expedientToEdit, setExpedientToEdit] = useState(initValues);
  const [errors, setErrors] = useState({});
  const [disableFields, setDisableFields] = useState(false);
  const loader = useContext(LoaderContext);
  const textForLegalActorRepresentative = useLegalRepresentativeText(expedientToEdit.actor_regime);
  const textForLegalDefendantRepresentative = useLegalRepresentativeText(expedientToEdit.defendant_regime);
  const [crimesSelectorOptions, setCrimesSelectorOptions] = useState([]);
  const [partiesInMainForm, setPartiesInMainForm] = useState([]);
  useEffect(() => {
    (async function () {
      loader.show(true);
      const optionsKJ = await getOptionsKindJudgment();
      const optionsLW = await getOptionsLegalWays();
      setKindJudgmentOptions(optionsKJ);
      setLegalWaysOptions(optionsLW);
      const expedient = await getDataExpedientToEdit(
        expedientId,
        optionsLW,
        optionsKJ
      );
      setExpedientToEdit(expedient ? expedient : initValues);
      loader.show(false);
    }())
    getAccountUser();
  }, []);

  useEffect(() => {
    if(expedientToEdit.matter === 'Penal'){
      getCrimesOptions();
    }
  }, [expedientToEdit.matter]);

  const getCrimesOptions = async () => {
    CrimesServices.getCrimesByMatter(`${expedientToEdit.matter}`.trim().toLowerCase(), setCrimesSelectorOptions);
  };

  const getAccountUser = () => {
    const { authentication_token_03 } = Cookies.get();

    if (!authentication_token_03) return '';
    let { user } = getDecodedJwt(authentication_token_03);
    if (!user) return '';
    let id_app = user.account_id ? user.account_id : '';
    if (id_app == 3) setDisableFields(true);

    return id_app;
  };

  const handleSubmit = async () => {
    const accountUser = getAccountUser();
    const requiredFields = accountUser!==3 ?['expedient_number', 'kindJudgmentOption', 'viaOption', 'reason', 'parties_array']:
    ['expedient_number', 'kindJudgmentOption', 'viaOption', 'reason'];

    const responseValidation = fieldsValidation(requiredFields, expedientToEdit, null, partiesInMainForm, true);
    if (typeof responseValidation === 'object') {
      setErrors(responseValidation);
    }
    else {
      loader.show(true);
      expedientToEdit['via'] = expedientToEdit['viaOption']['value'];
      expedientToEdit['kind_judgment'] = expedientToEdit['kindJudgmentOption']['value'];

      await saveExpedientEdited(
        props,
        expedientToEdit,
        setErrors
      );
      loader.show(false);
    }
  };

  const handleChange = (name, value) => {
    setExpedientToEdit({
      ...expedientToEdit,
      [name]: value
    });
    delete errors[name]
  };

  const matterSelectorOptions = [{ value: expedientToEdit.matter, label: expedientToEdit.matter }];
  return (
    <>
      <NavBar {...props} />

      <Row className="jc-c mT-25">
        <Card
          className="cardExpedientEdit"
        >
          <Card.Header
            className="cardHeaderExpedients"
          >
            <h4 className="text-center">{getLabelFromDictionary('editExpedient', 'editExpedientLabel') + ' ' + expedientToEdit['expedient_number']} <i className="ti-pencil-alt" style={{ fontSize: '15px' }} /></h4>
          </Card.Header>
          <Card.Body>
            <Container>
              <div className="table-responsive">
                <Form>
                  <Form.Group>
                    <Form.Label>{getLabelFromDictionary('editExpedient', 'numberExpedient')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`Edita el número de ${getLabelFromDictionary('global', 'sigleMainName')}`}
                      required
                      name="expedient_number"
                      value={expedientToEdit['expedient_number']}
                      onChange={(e) => {
                        if (/^[a-zA-Z0-9/]*$/.test(e.target.value)) {
                          handleChange(
                            'expedient_number',
                            e.target.value
                          )
                        }
                      }}
                      className={Object.keys(errors).includes("expedient_number") ? "text-danger is-invalid" : ""}
                      onInput={(e) => e.target.value = e.target.value.replace(/\b0+/g, '')}
                    />
                    {renderErrorsByInputName(errors, "expedient_number")}
                  </Form.Group>

                  <PartiesWithOutModal
                    showModal={true}
                    toggleShowModal={() => {}}
                    idExpedient={expedientId}
                    setPartiesInMainForm={setPartiesInMainForm}
                  />
                  {renderErrorsByInputName(errors, "parties_array")}

                  <Form.Group>
                    <Form.Label>{getLabelFromDictionary('editExpedient', 'trialType')}</Form.Label>
                    <Select
                      value={expedientToEdit['kindJudgmentOption']}
                      placeholder="Selecciona el tipo de juicio"
                      styles={Object.keys(errors).includes('kindJudgmentOption') ? selectStylesError : customStyles}
                      menuPlacement="bottom"
                      maxMenuHeight={150}
                      onChange={selected => handleChange(
                        'kindJudgmentOption',
                        selected
                      )}
                      options={kindJudgmentOptions}
                    />
                    {renderErrorsByInputName(errors, "kindJudgmentOption")}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{getLabelFromDictionary('editExpedient', 'via')}</Form.Label>
                    <Select
                      options={legalWaysOptions}
                      placeholder="Selecciona la vía"
                      styles={Object.keys(errors).includes('viaOption') ? selectStylesError : customStyles}
                      menuPlacement="bottom"
                      maxMenuHeight={150}
                      value={expedientToEdit['viaOption']}
                      onChange={selected => handleChange(
                        'viaOption',
                        selected
                      )}
                    />
                    {renderErrorsByInputName(errors, "viaOption")}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{getLabelFromDictionary('editExpedient', 'subject')}</Form.Label>
                    <Select
                      options={matterSelectorOptions}
                      onChange={selected => handleChange('matter', selected.value)}
                      placeholder="Selecciona la materia"
                      value={matterSelectorOptions.find((option) => option.value === expedientToEdit.matter)}
                      styles={Object.keys(errors).includes("matter") ? selectStylesError : selectStylesDefault}
                      isDisabled
                    />
                    {renderErrorsByInputName(errors, "matter")}
                  </Form.Group>

                 
                  <>
                    <CrimesMultiselect 
                      matter={expedientToEdit.matter || ''}
                      changeMainForm={handleChange}
                      defaultCrimes={expedientToEdit.crimes || ''}
                      isError={Object.keys(errors).includes("crimes")}
                      isUpdate={true}
                      handleChangeUpdate={handleChange}
                    />
                    {renderErrorsByInputName(errors, "crimes")}
                  </>
                  
                  {expedientToEdit.matter === 'Penal' &&
                    <Form.Group>
                      <Form.Label>Carpeta de investigación:</Form.Label>
                      <Form.Control
                        defaultValue={expedientToEdit["investigation_folder"]}
                        aria-label="investigation_folder"
                        onChange={(e) => handleChange( 'investigation_folder', e.target.value )}
                        className={Object.keys(errors).includes("investigation_folder") ? "text-danger is-invalid" : ""}
                      />
                      {renderErrorsByInputName(errors, "investigation_folder")}
                    </Form.Group> 
                  } 

                  <Form.Group>
                    <Form.Label>* Justificación:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      placeholder="Agrega una justificación"
                      defaultValue={expedientToEdit['reason']}
                      onChange={(e) => handleChange(
                        'reason',
                        e.target.value
                      )}
                      className={Object.keys(errors).includes("reason") ? "text-danger is-invalid" : ""}
                    />
                    {renderErrorsByInputName(errors, "reason")}
                  </Form.Group>
                </Form>
              </div>
            </Container>
          </Card.Body>
        </Card>
      </Row>
      <Row className="jc-c mB-20">
        <div className="actions-expedient-edit">
          <div className="fl-r">
            <Button
              variant="secondary"
              className="mR-20"
              onClick={() => props.history.goBack()}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => handleSubmit()}
            >
              Actualizar
            </Button>
          </div>
        </div>
      </Row>

      <Loader />
    </>
  );
};

export default EditExpedient;
