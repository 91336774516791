import { NotificationManager } from "react-notifications";
import { RequestNewVersion } from "../../classes/RequestNewVersion";

export const addPublicationDateFromNilo = async(data, type = 'sentences') => {
    try {
      const body = {};
      if(type === 'sentences') {
        body.sentences = data;
      } else {
        body.agreements = data;
      }
      const request = new RequestNewVersion(
          `${process.env.REACT_APP_URL_API_LARAVEL}/traditional_judicial_bulletin/bulletin_calculate_publication_date/${type}`,
          'post',
          {},
          body,
          {}
      );
  
      const requestSentences = await request.executeRequest();
      if (
          requestSentences &&
          requestSentences.code === 200 &&
          requestSentences.response
      ) {
          return requestSentences.response;
      }
      return [];
    } catch (err) {
      console.log(err);
      NotificationManager.error('', 'Error desconocido al obtener datos', 4500);
      return [];
    }
  
}