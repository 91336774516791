import React, { useState } from "react";
import { Form, Button, Container, Card, Spinner } from "react-bootstrap";
import ConfirmationModal from "../../modals/ConfirmationModal";
import styles from "./ExpedientForm.module.css";
import ExpedientFormFields from "./sections/ExpedientFormFields";
import SentenceFormFields from "./sections/SentenceFormFields";
import AgreementFormFields from "./sections/AgreementFormFields";
import { Separator } from "../../shared/Separator";
import { Formik } from "formik";
import { initialValues, validationExpedientFormSchema } from "./ExpedientForm.form";
import InfoExistentExpedient from "../info_expedient/InfoExistentExpedient";
import SelectField from "../../formikFields/SelectField";
import { createNewExpedient } from "../api/expedients";
import { addAgreementToExpedient } from "../api/agreements";
import { addSentenceToExpedient } from "../api/sentences";
import { useLoaderContext } from "../../layout/shared/loader_context";
import { addPartToExpedient } from "../api/parts";
import {
  DocumentTypesCatalog,
} from "../../../utils/BulletinCatalogs";
import PartsSection from "../parts/PartsSection";

export function ExpedientForm({ records, expedientId, setRecords }) {
  const [documentType, setDocumentType] = useState("");
  const [show, setShow] = useState(false);
  const [isAgreement, setIsAgreement] = useState(false);
  const [isSentence, setIsSentence] = useState(false);
  const [formikValues, setFormikValues] = useState({});
  const foundExpedient = records && records.length;

  const loader = useLoaderContext();
  const handleDocumentTypeSelect = (e) => {
    const selectedValue = e.value;
    setDocumentType(selectedValue);

    if (selectedValue === 1) {
      setIsAgreement(true);
      setIsSentence(false);
    } else {
      setIsAgreement(false);
      setIsSentence(true);
    }
  }

  const handlePublishExpedient = async () => {
    let expedientDocument = expedientId;
    const { document_type } = formikValues.expedient;

    loader.show(true);
    if (expedientId === null) {
      const storageParts = JSON.parse(localStorage.getItem('parts'));
      if (storageParts.length === 0) {
        alert('Debes agregar al menos una parte al expediente');
        loader.show(false);
        return;
      }

      const newExpedient = await createNewExpedient(formikValues.expedient);
      if (newExpedient) {
        expedientDocument = newExpedient.id;
      }

      try {
        storageParts.map(async (part) => {
          await addPartToExpedient({
            partClasification: part.partClasification,
            partType: part.partType,
            expedientId: expedientId,
            partNames: part.partNames,
            paternalSurname: part.paternalSurname,
            maternalSurname: part.maternalSurname,
            partSex: part.partSex,
            partAlias: part.partAlias,
            partGender: part.partGender,
            partAge: part.partAge,
            partEmail: part.partEmail,
            partPhone: part.partPhone,
            partBirthday: part.partBirthday,
            partRegime: part.partRegime,
            partBusinessName: part.partBusinessName
          }, expedientDocument);
        });

        localStorage.removeItem('parts');
      } catch (error) {
        console.log('Error al agregar partes al expediente', error);
      }
    }

    if (document_type.value === 1) {
      const { agreement } = formikValues;
      await addAgreementToExpedient(agreement, expedientDocument, setRecords);
    } else {
      const { sentence } = formikValues;
      const { document_type } = formikValues.expedient;
      await addSentenceToExpedient(sentence, expedientDocument, document_type, setRecords);
    }
    loader.show(false);
    setShow(false);
  }

  return (
    <Container className={styles.container}>
      <Card>
        <Card.Header>
          <h4 className={styles.title}>{foundExpedient ? 'Expediente encontrado' : 'Alta de nuevo expediente'}</h4>
        </Card.Header>

        <Card.Body>
          <Container>
            {foundExpedient > 0 &&
              <>
                <InfoExistentExpedient records={records} showParts={true} />
                <Separator />
              </>
            }

            <Formik
              initialValues={initialValues()}
              validationSchema={validationExpedientFormSchema(Boolean(foundExpedient))}
              validateOnChange={false}
              onSubmit={async (values) => {
                setShow(true);
                setFormikValues(values);
              }}
            >
              {
                (props) => {
                  return (
                    <form onSubmit={props.handleSubmit}>

                      {records && records.length === 0 &&
                        <>
                          <ExpedientFormFields />
                          <PartsSection expedient={records[0]} />
                          <Separator />
                        </>
                      }

                      <div className={styles.containerRowFields}>
                        <div className={styles.largeSize}>
                          <Form.Group>
                            <div className={styles.fieldLabel}>
                              * Tipo de documento a publicar
                            </div>
                            <SelectField
                              id="document_type"
                              name='expedient.document_type'
                              placeholder="Selecciona una opción"
                              options={DocumentTypesCatalog}
                              onChange={handleDocumentTypeSelect}
                            />
                            <small>Selecciona un tipo de expediente</small>
                          </Form.Group>
                        </div>
                      </div>

                      {isSentence &&
                        <>
                          <Separator />
                          <SentenceFormFields 
                            expedientId={expedientId}
                          />
                        </>
                      }

                      {isAgreement &&
                        <>
                          <Separator />
                          <AgreementFormFields />
                        </>
                      }

                      <div className={styles.actionButtonsContainer}>
                        <Button
                          variant="primary"
                          type="submit"
                          className={styles.button}
                          disabled={documentType === "" ? true : false}
                        >
                          Publicar en Boletín
                        </Button>
                      </div>

                      <ConfirmationModal show={show} setShow={setShow} handlePublishExpedient={handlePublishExpedient} />
                    </form>
                  );
                }
              }
            </Formik>
          </Container>
        </Card.Body>
      </Card>
    </Container >
  );
}