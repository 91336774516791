import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { CrimesServices } from '../../../services/crimes/crimesServices';
import { Form } from 'react-bootstrap';
import { selectStylesDefault, selectStylesError } from '../../government_book/my_expedients/expedients_list_logic';

export const CrimesMultiselect = ({matter = '', changeMainForm, defaultCrimes = '', isDisabled = false, isError = false, isUpdate = false, handleChangeUpdate}) => {
    const [crimes, setCrimes] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    // const [defValues, setDefValues] = useState([]);
    const [selectedCrimes, setSelectedCrimes] = useState([]);

    useEffect(() => {
        if(matter) CrimesServices.getCrimesByMatter(`${matter}`.trim().toLowerCase(), setCrimes); 
    }, [matter]);

    useEffect(() => {
        if(crimes.length > 0) setFilterOptions(crimes.slice(0, 50));
    }, [crimes]);

    useEffect(() => {
        if(defaultCrimes != '' && crimes.length > 0) {
            let defaultCrimesArray = defaultCrimes.split(',');
            defaultCrimesArray = defaultCrimesArray.map((item) => parseInt(item));
            const selected = crimes.filter((item) => defaultCrimesArray.includes(item.value));
            if(selected.length > 0) setSelectedCrimes(selected);
        }
    }, [defaultCrimes, crimes]);

    const handleInputChange = (inputValue) => {
        const newOptions = crimes.filter(option => option && option.label && option.label.toLowerCase().includes(inputValue.toLowerCase()));
        setFilterOptions(newOptions);
    }

    const handleSelectChange = (selected) => {
        if(selected) {
            setSelectedCrimes(selected);
            const selectedIds = selected.map((item) => item.value);
            const stringIds = selectedIds.join(',');
            if(isUpdate) {
                handleChangeUpdate('crimes', stringIds);
            } else {
                changeMainForm((prev) => ({...prev, crimes: stringIds}));
            }
        } else {
            setSelectedCrimes([]);
            if(isUpdate) {
                handleChangeUpdate('crimes', '');
            } else {
                changeMainForm((prev) => ({...prev, crimes: ''}));
            }
        }
    }

    return (
        <Form.Group>
            {
                matter.toLowerCase() == 'penal' ? <Form.Label>Delitos:</Form.Label> : <Form.Label>Acción principal:</Form.Label>
            }
            <ReactSelect
                isDisabled={isDisabled}
                options={filterOptions}
                maxMenuHeight={150}
                onInputChange={handleInputChange}
                isMulti={true}
                onChange={handleSelectChange}
                value={selectedCrimes}
                styles={isError ? selectStylesError : selectStylesDefault}
                

            /> 
        </Form.Group>
    )
}
