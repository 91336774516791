import React, { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import WrapperFormikField from './WrapperFormikField';
import { useDropzone } from 'react-dropzone';
import styles from './ErrorMessagesShared.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

export default function DropzoneField({ onChange = () => { }, ...restOfProps }) {
  const { setFieldValue } = useFormikContext();
  const [file, setFile] = useState({});
  const [isAdded, setIsAdded] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    setFile({
      path: acceptedFiles[0].path,
      name: acceptedFiles[0].name,
      type: acceptedFiles[0].type,
      size: acceptedFiles[0].size

    }
    );
  }, [])

  const handleDeleteFile = () => {
    setFieldValue(restOfProps.name, null);
    acceptedFiles[0] = null;
    setIsAdded(false);
  };

  const handleAddFile = () => {
    setFile({});
    setFieldValue(restOfProps.name, acceptedFiles[0]);
    setIsAdded(true);
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone(
    {
      accept: 'application/pdf',
      multiple: false,
      onDrop
    },
  );

  return (
    <WrapperFormikField {...restOfProps}>
      {({ errorField }) => (
        <>
          <div {...getRootProps()}
            className={errorField ? styles.dropZoneBorderError : styles.dropZoneBorder}
          >
            <input {...getInputProps()} />
            {
              isDragActive ?
                <p>Suelta los archivos aqui...</p> :
                Object.keys(file).length > 0 ?
                  <p className='backgroung-white'>Archivo cargado: {acceptedFiles[0].name}</p> 
                :
                <>
                < i class="fas fa-file-alt h1" style={{ fontSize: "60px", color: "#1D78FF" }}></i>
                <p
                  style={{ textAlign: "center", marginTop: "10px", color: "#1D78FF", fontbold: "semibold" }}
                >Arrastra y suelta los archivos aquí o haz clic para seleccionar archivos</p>
                </>
            }
          </div>
          <small>Asegúrese de cargar un documento testado. El documento debe ser pdf</small>
          <div  style={{ display: "flex", justifyContent: "end"}}>
          <Button
              onClick={handleDeleteFile}
              style={{ marginRight: "10px", backgroundColor:"white", border:"solid 2px #1D78FF", color:"#1D78FF" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
              <i class="fas fa-trash-alt" style={{ fontSize: "15px" }}></i>
              <a style={{marginLeft:"4px", fontSize: "15px", fontWeight: "bold"}}>Eliminar archivo</a>
              </div>
            </Button>
            <Button
              variant="primary"
              onClick={handleAddFile}
              style={{alignSelf: "flex-end", marginRight: "10px", border:"solid 2px #1D78FF"}}
            
            >
              <div style={{ display: "flex", alignItems: "center" }}>
              <i class="fas fa-plus-square" style={{ fontSize: "20px" }}></i>
              <a style={{marginLeft:"4px", fontSize: "15px", fontWeight: "bold"}}>Agregar archivo</a>
              </div>
            </Button>
          </div>


          {acceptedFiles[0] && isAdded && (
            <div
              style={{ marginTop: "20px" }}
            >
              {acceptedFiles[0].type === 'application/pdf' ?
                <div className="text-center">
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    color="red"
                    style={{
                      width: "250px",
                      height: "300px",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                  />
                  <p className="mT-15">{acceptedFiles[0].name}</p>
                </div>
                :
                <img src={URL.createObjectURL(acceptedFiles[0])} alt=''
                  style={{
                    width: "350px",
                    height: "350px",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }
                  }
                />
              }
            </div>
          )}

          {errorField && <div className={styles.feedbackErrorMessages}><li>{errorField}</li></div>}
        </>
      )
      }
    </WrapperFormikField >
  )
}