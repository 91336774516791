import React from 'react';
import { Container, Card, Button, Form } from "react-bootstrap";
import { Formik } from 'formik';
import { initialValues, validationSchema } from './SearchForm.form';
import SelectField from '../../formikFields/SelectField';
import TextField from '../../formikFields/TextField';
import styles from './SearchForm.module.css';

export function SearchForm({ getExpedientRecords, setRecords, expedientTypes: ExpedientTypesCatalog }) {
  const handleReset = (formikContext) => {
    formikContext.handleReset();
    localStorage.removeItem('parts');
    setRecords(null);
  }

  return (
    <Container>
      <Card style={{ maxWidth: '100%'}}>
        <Card.Header className="text-center">
          <h4>Búsqueda de expediente</h4>
        </Card.Header>
        <Card.Body>
          <Container>
            <Formik
              initialValues={initialValues()}
              validationSchema={validationSchema()}
              validateOnChange={false}
              onSubmit={(values) => {
                const { expedient_number, expedient_type } = values;
                getExpedientRecords(expedient_number, expedient_type)
              }}
            >
              {
                (props) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <Form.Group>
                        <div className="d-flex flex-wrap justify-content-around">
                          <div className="col-md-5 col-sm-12">
                            <Form.Label className="text-left font-weight-bold">
                              * Número de expediente
                            </Form.Label>

                            <TextField
                              id="expedient_number"
                              name="expedient_number"
                              placeholder="Número de expediente"
                              className={styles.input}
                            />
                            <small>Proporciona el número de expediente a buscar</small>
                          </div>

                          <div className="col-md-5 col-sm-12" style={{ zIndex: 2 }}>
                            <Form.Label className="text-left font-weight-bold">
                              * Tipo de expediente
                            </Form.Label>

                            <SelectField
                              id="expedient_type"
                              name='expedient_type'
                              placeholder="Selecciona una opción"
                              options={ExpedientTypesCatalog}
                            />
                            <small>Selecciona un tipo de expediente</small>
                          </div>
                        </div>
                      </Form.Group>

                      <div className={styles.actionButtons}>
                        <Button
                          variant="secondary"
                          onClick={() => handleReset(props)}
                        >
                          Nueva Búsqueda
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                        >
                          Buscar Expediente
                        </Button>
                      </div>

                    </form>
                  );
                }
              }
            </Formik>
          </Container>
        </Card.Body>
      </Card>
    </Container >
  );
}
