// import { getAgreementsByExpedient, deleteAgreement } from './agreements';
// import { getSentencesByExpedient, deleteSentence } from './sentences';

import { NotificationManager } from "react-notifications";
import { RequestNewVersion } from "../../classes/RequestNewVersion";

const findExpedient = async (expedientId) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
      },
    }

    const expedientResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_BACKEND_PATH}` + `/api/expedients/${expedientId}`,
      requestOptions);

    const expedient = await expedientResponse.json();
    return expedient.data;
  } catch (error) {
    console.error(error);
  }
}

const createNewExpedient = async (expedient) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`
      },
      body: JSON.stringify({
        data: {
          recordDate: expedient.record_date,
          expedientNumber: expedient.expedient_number,
          expedientType: expedient.expedient_type,
          judgment: expedient.judgment,
          origin: expedient.origin,
          externalExpedient: expedient.external_expedient,
          externalDependence: expedient.external_dependence,
          type: expedient.expedient_type.value
        }
      }),
    }

    const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND_PATH}/api/expedients`, requestOptions);
    const dataResponse = await response.json();

    if(response.status === 200) {
      NotificationManager.success('Expediente creado correctamente');
    } else if (response.status === 400) {
      const {error = null} = dataResponse;
      if(error) {
        const {message = null} = error;
        if(message) NotificationManager.error(message);
      }
    }
    return dataResponse.data;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

// const deleteExpedient = async (expedientId) => {
//   try {
//     const allAgreementsByExpedient = await getAgreementsByExpedient(expedientId);
//     allAgreementsByExpedient.forEach(async (agreement) => {
//       const { id } = agreement;
//       await deleteAgreement(id);
//     });

//     const allSentencesByExpedient = await getSentencesByExpedient(expedientId);
//     allSentencesByExpedient.forEach(async (sentence) => {
//       const { id } = sentence;
//       await deleteSentence(id);
//     });

//     const expedientResponse = await fetch(
//       `${process.env.REACT_APP_STRAPI_BACKEND_PATH}` + `/api/expedients/${expedientId}`,
//       { method: 'DELETE' }
//     );
//     const deletedExpedient = await expedientResponse.json();

//     console.log("Expediente eliminado correctamente", deletedExpedient);
//   } catch (error) {
//     console.error(error);
//     console.log("Ocurrio un error al eliminar el expediente");
//   }
// }

const editExpedient = async (expedientId, expedient) => {
  try {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`
      },
      body: JSON.stringify({
        data: {
          recordDate: expedient.record_date,
          expedientNumber: expedient.expedient_number,
          expedientType: expedient.expedient_type,
          judgment: expedient.judgment,
          origin: expedient.origin,
          externalExpedient: expedient.external_expedient,
          externalDependence: expedient.external_dependence,
          type: expedient.expedient_type.value
        }
      }),
    }

    const editResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_BACKEND_PATH}/api/expedients/${expedientId}`, requestOptions
    );
    const updatedExpedient = await editResponse.json();

    return updatedExpedient.data.attributes;
  } catch (error) {
    console.error(error);
    console.log("Ocurrio un error al editar el expediente");
  }
}

const searchExpedient = async (expedient, expedientType) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
      },
    }

    const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND_PATH}` +
      `/api/expedients?filters[expedientNumber][$eq]=${expedient}` +
      `&filters[type][$eq]=${expedientType.value}`, requestOptions);

    const dataResponse = await response.json();

    return dataResponse;
  } catch (error) {
    console.error(error);
    console.log("Ocurrio un error al consultar la fuente de expedientes");
  }
}

const getTypesExpedientsRequest = async () => {
  try {
    const request = new RequestNewVersion(
      `${process.env.REACT_APP_URL_API_LARAVEL}/expedientTypes`,
      'get',
    );


    const requestTypes = await request.executeRequest();
    if (
      requestTypes &&
      requestTypes.code === 200 &&
      requestTypes.response
    ) {
        let data = requestTypes.response;
        return data.data;
    }

    return [];
  } catch(err) {
    return [];
  }
}

export {
  findExpedient,
  // deleteExpedient,
  editExpedient,
  createNewExpedient,
  searchExpedient,
  getTypesExpedientsRequest
};
