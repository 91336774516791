import React from "react";
import NavBar from "../layout/shared/NavBar";
import Loader from "../layout/shared/Loader";
import Footer from "../layout/shared/Footer";
import { SearchForm } from "./search_form/SearchForm";
import { ExpedientForm } from "./expedient_form/ExpedientForm";
import { BulletinResults } from "./bulletin_results/BulletinResults";
import useJuditialBulletin from "./hooks/useJuditialBulletin";
import SectionTitle from "./section_title/SectionTitle";

const JuditialBulletin = props => {
    const { getExpedientRecords, records, setRecords, expedient, expedientId, expedientTypes } = useJuditialBulletin();

    return (
        <>
            <NavBar {...props} />

            <SectionTitle text="Boletín Judicial Tradicional" />

            <SearchForm getExpedientRecords={getExpedientRecords} setRecords={setRecords} expedientTypes={expedientTypes} />

            <BulletinResults records={records} expedient={expedient} expedientId={expedientId} />

            {Array.isArray(records) &&
                <ExpedientForm records={records} expedientId={expedientId} setRecords={setRecords} />
            }
            
            <Footer />

            <Loader />
        </>
    );
};

export default JuditialBulletin;