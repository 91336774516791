import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  OriginsCatalog
} from '../../../../utils/BulletinCatalogs';
import Cookies from 'js-cookie';
import TextField from '../../../formikFields/TextField';
import SelectField from '../../../formikFields/SelectField';
import DateField from '../../../formikFields/DateField';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import styles from '../ExpedientForm.module.css';
import useJuditialBulletin from '../../hooks/useJuditialBulletin';

export default function ExpedientForm() {
  const [judgementTypesOptions, setJudgementTypesOptions] = useState([]);
  const {expedientTypes: ExpedientTypesCatalog} = useJuditialBulletin();

  async function getJudgementTypes() {
    let authenticationToken = Cookies.get("authentication_token_03");

    const judgementResponse = await fetch(
      `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/catalogs/judgement_types`, {
      method: 'GET',
      headers: {
        'Authorization': authenticationToken
      }
    });

    const judgementData = await judgementResponse.json();
    const judgementOptions = judgementData.data.judgementTypes;
    const options = judgementOptions.map((judgement) => {
      return {
        value: judgement.id,
        label: judgement.name
      }
    });

    setJudgementTypesOptions(options);
  }

  return (
    <>
      <div className={styles.containerRowFields}>
        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Fecha de registro
            </div>
            <div className={styles.calendarFieldApparience}>
              <div className={styles.calendarGroup}>
                <div className={styles.datePickerElement}>
                  <DateField
                    name="expedient.record_date"
                    id="record_date"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className={styles.inputDate}
                    placeholderText={"Clic para seleccionar fecha"}
                  />
                </div>
                <div className={styles.iconPicker}>
                  <FontAwesomeIcon icon={faCalendarDays} className={styles.calendarIcon} />
                </div>
              </div>
            </div>
          </Form.Group>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.mediumSize}>
          <div className={styles.fieldLabel}>
            * Número de expediente
          </div>
          <TextField
            id="expedient.expedient_number"
            name="expedient.expedient_number"
            placeholder="Número de expediente"
            className={styles.input}
          />
          <small>Escribe aqui el nombre de la dependencia externa</small>
        </div>

        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Tipo de expediente
            </div>
            <SelectField
              id="expedient.expedient_type"
              name='expedient.expedient_type'
              placeholder="Selecciona una opción"
              options={ExpedientTypesCatalog}
            />
            <small>Selecciona el tipo al que corresponde el expediente</small>
          </Form.Group>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Juicio
            </div>
            <SelectField
              id="expedient.judgment"
              name='expedient.judgment'
              placeholder="Selecciona una opción"
              onFocus={() => getJudgementTypes()}
              options={judgementTypesOptions}
            />
            <small>Selecciona el juicio correspondiente de acuerdo a la materia</small>
          </Form.Group>
        </div>

        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Origen
            </div>
            <SelectField
              id="expedient.origin"
              name='expedient.origin'
              placeholder="Selecciona una opción"
              options={OriginsCatalog}
            />
            <small>Origen del expediente</small>
          </Form.Group>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.mediumSize}>
          <div className={styles.fieldLabel}>
            Expediente externo
          </div>
          <TextField
            id="expedient.external_expedient"
            name="expedient.external_expedient"
            placeholder="Expediente externo origen"
            className={styles.input}
          />
          <small>Escribe el número de expediente externo</small>
        </div>

        <div className={styles.mediumSize}>
          <div className={styles.fieldLabel}>
            Dependencia externa
          </div>
          <TextField
            id="expedient.external_dependence"
            name="expedient.external_dependence"
            placeholder="Dependencia externa"
            className={styles.input}
          />
          <small>Escribe aqui el nombre de la dependencia externa</small>
        </div>
      </div>
    </>
  );
}