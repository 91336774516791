export const procedureTypeOptions = [
  {
    "label": "Apelación",
    "value": 1
  },
  {
    "label": "Queja",
    "value": 2
  },
  {
    "label": "Recusación",
    "value": 3
  },
  {
    "label": "Incompetencia",
    "value": 4
  },
  {
    "label": "Excusa",
    "value": 5
  },
  { 
    "label": "Carta rogatoria",
    "value": 6
  },
  {
    "label": "Certificaciones",
    "value": 7
  },
  {
    "label": "Impedimentos",
    "value": 8
  }
];