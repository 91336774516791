import React  from 'react'
import FormTitle from '../../FormTitle'
import { Button,Form, Row } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import AddMinorModal from './components/modals/addMinorModal/AddMinorModal';
import { useIndicatorsContext } from '../../../context/indicatorsContext/IndicatorsContext';
import { columns } from './components/modals/addMinorModal/services/involvedMinorsTable/columns';
import _ from 'lodash';
import { initialDemandsDefaultValues } from '../../../constants/defaultValues';
import { renderErrorsByInputName } from '../../../../../../my_expedients/expedients_list_logic';

const InitialDemand = () => {
  const { initialDemand } = useIndicatorsContext();
  const [minors, setMinors] = React.useState(null);

  
  const handleMimorsChange = (name,value) => {
    initialDemands.onFormChange({target:{name,value}});
    setMinors(value);
  }
  
  const {
    toggleisAddMinorModalShowing,
    isAddMinorModalShowing,
    initialDemands,
    assertions,
    errors,
  } = initialDemand;

  const checkMinorInvolvement = () => {
    return (
      (assertions.isThereAMinorInvolved === true && minors !== false) ||
      (assertions.isThereAMinorInvolved === false && minors === true)
    );
  };

  return (
    <div>
      <FormTitle>
        Demanda Inicial
      </FormTitle>
      <Form>
        <Form.Group controlId="measures_reparation">
          <Form.Label>
            Medidas de reparacion del daño solicitadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_reparation'
            onChange={initialDemands.onFormChange}
            value={_.defaultTo(initialDemands.formValues.measures_reparation, initialDemandsDefaultValues.measures_reparation)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName( errors,'measures_reparation')}
        </Form.Group>

        <Form.Group controlId="measures_protection">
          <Form.Label>
            Medidas de proteccion solicitadas
          </Form.Label>
          <Form.Control
            type="number"
            name='measures_protection'
            onChange={initialDemands.onFormChange}
            value={_.defaultTo(initialDemands.formValues.measures_protection, initialDemandsDefaultValues.measures_protection)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName( errors,'measures_protection')}
        </Form.Group>

        <Form.Group controlId="minors">
          <Form.Text className="text-muted">
            Menores de edad involucrados
          </Form.Text>
          <Form.Check
            inline
            type="radio"
            label="Si"
            name="minors"
            id='minors_radio_si'
            value={true}
            checked={minors!==null? minors : assertions.isThereAMinorInvolved}
            onChange={()=>handleMimorsChange('minors',true)}
          />
          <Form.Check
            inline
            type="radio"
            label="No"
            name="minors"
            id='minors_radio_no'
            value={false}
            checked={minors!== null ? !minors : assertions.isNotThereAMinorInvolved}
            onChange={()=>handleMimorsChange('minors',false)}
          />
        </Form.Group>

        {
          checkMinorInvolvement() &&
          <>
            <Row style={{ marginBottom: '12px', justifyContent: 'flex-end' }}>
              <Button variant="success" onClick={toggleisAddMinorModalShowing}>
                <i className="fas fa-plus" />
                {'  '} Agregar Menor
              </Button>
            </Row>
            <Row style={{ overflow: 'auto', display: 'block' }}>
              <BootstrapTable
                keyField="involved_minors_table"
                data={initialDemands.formValues.involved_minors? initialDemands.formValues.involved_minors : []}
                columns={columns}
              />
            </Row>
          </>
        }
      </Form>

      {
        isAddMinorModalShowing
        &&
        <AddMinorModal />
      }
    </div >
  )
}

export default InitialDemand